const Sparkle = ({disabled}) => {
  let fillColour = disabled ? "#777777" : "white";
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_494_1443)">
        <path
          d="M7.12174 3.16547L9.09999 7.51763L13.4522 9.49589L9.09999 11.4741L7.12174 15.8263L5.14348 11.4741L0.791321 9.49589L5.14348 7.51763L7.12174 3.16547ZM7.12174 6.98746L6.33043 8.70458L4.61331 9.49589L6.33043 10.2872L7.12174 12.0043L7.91304 10.2872L9.63016 9.49589L7.91304 8.70458L7.12174 6.98746ZM15.0348 7.12198L14.0377 4.95381L11.8695 3.95677L14.0377 2.96765L15.0348 0.791565L16.0239 2.96765L18.2 3.95677L16.0239 4.95381L15.0348 7.12198ZM15.0348 18.2002L14.0377 16.032L11.8695 15.035L14.0377 14.0459L15.0348 11.8698L16.0239 14.0459L18.2 15.035L16.0239 16.032L15.0348 18.2002Z"
          fill={fillColour}
        />
      </g>
      <defs>
        <clipPath id="clip0_494_1443">
          <rect
            width="18.9912"
            height="18.9912"
            fill={fillColour}
          />
        </clipPath>
      </defs>
    </svg>

  );
};

export default Sparkle;
