import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Breadcrumb.module.css";
import canvases from "../../data/canvas.json";

const Breadcrumb = () => {
  const navigate = useNavigate();
  let location = useLocation();

  const handleBackClick = () => {
    if (location.pathname === "/agent") {
      let config = location.state.configFile;
      let canvas = canvases.find((canvas) =>
        canvas.agents.some((obj) => obj.config === config)
      );
      navigate("/canvas", { state: { selectedCanvas: canvas } });
    } else {
      navigate("/");
    }
  };
  return (
    <nav className={styles.breadcrumb}>
      <button type="button" onClick={handleBackClick}>
        <img src="/icons/icon-breadcrumb.svg" alt="User Icon" />
        Back
      </button>
    </nav>
  );
};

export default Breadcrumb;
