import React from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ActionButtons from "../ActionButtons/ActionButtons";
import styles from "./NavBar.module.css";
import { useLocation } from "react-router-dom";
const NavBar = () => {
  let location = useLocation();

  return (
    <div className={styles.navBar}>
      <Breadcrumb />
      <nav>
        <ActionButtons
          notAgentPage={location.pathname === "/agent" ? false : true}
        />
      </nav>
    </div>
  );
};

export default NavBar;
